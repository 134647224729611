import initModal, { ModalProps } from '@kit/Modal';
import AuthHandler from './helpers/authHandler';

import './ModalAuthorization.scss';

const AUTH_MODAL_ID = 'authorizeModal';
const AUTH_MODAL_TARGET = 'authorizeModal-target';

type AuthorizeModalProps = {
    triggerSelector?: string;
};

const ModalAuthorization = ({ triggerSelector }: AuthorizeModalProps) => {
    const props: ModalProps = {
        modalId: AUTH_MODAL_ID,
        targetId: AUTH_MODAL_TARGET,
        triggerSelector,
        onShowHandler: () => {
            const authHandler = new AuthHandler();
        },
        className: 'authorize-modal'
    };

    window.addEventListener('load', (e) => {
        initModal(props);
    });
};

export default ModalAuthorization;
