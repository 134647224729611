import Banner from './components/Banner';
import Instruction from './components/Instruction';
import Products from '@components/Products';
import Categories from './components/Categories';
import ProductCard from '@kit/ProductCard';

import './Home.scss';

const Home = () => {
    Banner();
    Instruction();
    Products();
    ProductCard();
    Categories();
};

export default Home;
