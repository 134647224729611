import initModal, {ModalProps} from '@kit/Modal';
import './DropdownPersonal.scss';

const MODAL_ID = 'personalDropdownModal';

const DropdownPersonal = () => {
    const props: ModalProps = {
        modalId: MODAL_ID,
        targetId: `${MODAL_ID}-target`,
        customTriggerSelector: '#js-personal-dropdown-custom-trigger',
        className: 'personal-modal',
        bindCustomTrigger: true
    };

    window.addEventListener('load', () => {
        initModal(props);
    });
};

export default DropdownPersonal;
