import initModal, { ModalProps } from '@kit/Modal';
import './DropdownHeader.scss';

const MODAL_ID = 'headerDropdownModal';

const DropdownHeader = () => {
    const props: ModalProps = {
        modalId: MODAL_ID,
        targetId: `${MODAL_ID}-target`,
        triggerSelector: '#js-header-trigger',
        className: 'header-modal',
        bindCustomTrigger: true
    };
    initModal(props);
};

export default DropdownHeader;
