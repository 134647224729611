import './ProductCard.scss';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';
import actionFavourites, { getInitialFavourites } from '@helpers/favourites';

const baseClass = 'icon-heart-simple';
const activeClass = 'icon-heart-fill';

const ProductCard = async () => {
    tippy('.js-price-tooltip', {
        theme: 'light-border',
        content: 'С учетом кэшбэка',
        placement: 'bottom',
        animation: 'fade'
    });

    const favouritesHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const icon = e.target;
        const productId = (icon.parentNode as HTMLElement).dataset.id;
        const added = icon.classList.contains(activeClass);
        actionFavourites(productId, added ? 'delete' : 'add').then((data: any) => {
            if (data?.success) {
                icon.classList.remove(added ? activeClass : baseClass);
                icon.classList.add(added ? baseClass : activeClass);
            }
        })
    }

    const initFavourites = async () => {
        const elements = document.querySelectorAll('.js-favorite-toggle');
        if (!!elements.length) {
            const addedIds: number[] = await getInitialFavourites();
            elements.forEach((el) => {
                const productId = (el as HTMLElement).dataset.id;
                const icon = el.querySelector('span');
                if (addedIds.includes(parseInt(productId))) {
                    icon.classList.remove(baseClass);
                    icon.classList.add(activeClass);
                }
                if (productId) {
                    el.removeEventListener('click', favouritesHandler);
                    el.addEventListener('click', favouritesHandler);
                }
            })
        }
    };
    initFavourites();

    document.addEventListener('onPageChanged', initFavourites);
};

export default ProductCard;
