import $ from 'jquery';
// import 'bootstrap/dist/js/bootstrap.bundle.js';
import Header from '@components/Header';
import Footer from '@components/Footer';
import ModalAuthorization from '@components/ModalAuthorization';

import './Page.scss';

const Page = () => {
    Header();
    Footer();

    ModalAuthorization({
        triggerSelector: '.js-auth_modal'
    });

    $("[data-category]").on('click', function (event) {
        const $thumbnail = $(event.target).closest('.thumbnail');
        const slug = $thumbnail.data('category-slug')
        window.location.href = '/catalog/' + slug
    })

    const filtersTrigger = document.getElementById('js-filters-trigger');
    const formContainer = document.getElementById('js-form-container');

    if (filtersTrigger && formContainer) {
        filtersTrigger.addEventListener('click', function () {
            filtersTrigger.classList.toggle('active');

            filtersTrigger.classList.contains('active')
                ? formContainer?.classList.add('open')
                : formContainer?.classList.remove('open');
        })
    }
};

export default Page;
