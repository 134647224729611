import $ from 'jquery';

const getCsrf = () => {
    const csrfMetaEl = document.querySelector<HTMLMetaElement>("meta[name='csrf-token']");
    return csrfMetaEl?.content || null;
}

const actionFavourites = (productId, type: 'add' | 'delete' = 'add') => {
    const url = new URL(`/customer/favorites/${type}/`, document.baseURI);
    return new Promise((resolve, reject) => {
        const _csrf = getCsrf();
        $.ajax({
            url: url,
            type: 'POST',
            data: {
                productId,
                _csrf
            },
            success: function (data) {
                resolve(data)
            },
            error: function (error) {
                reject(error)
            },
        });
    });
};

export const getInitialFavourites = (): Promise<number[]> => {
    return new Promise((resolve, reject) => {
        const _csrf = getCsrf();
        const url = new URL(`/customer/favorites/get-all/`, document.baseURI);
        $.ajax({
            url,
            type: 'POST',
            data: {
                _csrf
            },
            success: function (data) {
                resolve(JSON.parse(data))
            },
            error: function (error) {
                reject(error)
            }
        });
    })
}

export default actionFavourites;