import MicroModal from 'micromodal';
import './Modal.scss';

export type ModalProps = {
    modalId: string;
    targetId: string;
    triggerSelector?: string;
    className?: string;
    bindCustomTrigger?: boolean;
    triggerActiveClass?: string;
    customTriggerSelector?: string;
    onShowHandler?: Function;
    onCloseHandler?: Function;
};

const getTemplate = (
    modalId: string,
    className: string,
    modalContent: string
) =>
    `<div id="${modalId}" aria-hidden="true" class="modal ${className}">
        <div tabindex="-1" data-micromodal-close class="modal-overlay">
            <div role="dialog" aria-modal="true" aria-labelledby="${modalId}-title" class="modal-dialog">
                <div aria-label="Close modal" data-micromodal-close class="modal-close icon-cross"></div>
                <div id="${modalId}-content" class="modal-content">
                    ${modalContent}
                </div>
            </div>
        </div>
    </div>`;

const createModalElement = ({ className, targetId, modalId }: ModalProps) => {
    const target = document.getElementById(targetId);

    if (!target) return;

    const contentHtmlString = target?.innerHTML;
    const modalHtmlString = getTemplate(modalId, className, contentHtmlString);
    document.body.insertAdjacentHTML('beforeend', modalHtmlString);
    target.remove();
};

const initCustomTrigger = (props: ModalProps) => {
    const {
        modalId,
        customTriggerSelector,
        triggerActiveClass = 'active',
        onShowHandler,
        onCloseHandler
    } = props;

    const triggers = document.querySelectorAll(customTriggerSelector);
    if (!triggers?.length) return;

    triggers.forEach((trigger) => {
        (trigger as HTMLElement).onclick = (e) => {
            e.preventDefault();
            e.stopPropagation();
            const isShown = trigger.classList.contains(triggerActiveClass);
            if (isShown) {
                MicroModal.close(modalId);
                trigger.classList.remove(triggerActiveClass);
                document.body.style.overflow = null;
                onCloseHandler && onCloseHandler();
            } else {
                MicroModal.show(modalId);
                trigger.classList.add(triggerActiveClass);
                document.body.style.overflow = 'hidden';
                onShowHandler && onShowHandler();
            }
        };
    });
};

const Modal = (props: ModalProps) => {
    const {
        modalId,
        triggerSelector,
        bindCustomTrigger = false,
        onShowHandler,
        onCloseHandler
    } = props;

    createModalElement(props);
    bindCustomTrigger && initCustomTrigger(props);
    document.querySelectorAll(triggerSelector).forEach((el) => {
        el.setAttribute('data-micromodal-trigger', modalId);
    });
    MicroModal.init({
        disableScroll: true,
        onShow: () => {
            document.body.style.overflow = 'hidden';
            onShowHandler && onShowHandler();
        },
        onClose: () => {
            document.body.style.overflow = null;
            onCloseHandler && onCloseHandler();
        }
    });
};

export default Modal;
