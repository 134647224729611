import Timer from '@helpers/timer';

class StepsHandler {
    steps: {
        first?: HTMLElement;
        second?: HTMLElement;
    };
    timer: Timer;
    phone: string;

    constructor(authData) {
        this.steps = {
            first: document.getElementById('js-auth-step-1'),
            second: document.getElementById('js-auth-step-2')
        };
        this.phone = authData.phone;
        this.timer = new Timer(parseInt(authData.expire), 'js-auth-timer-min', 'js-auth-timer-sec');
    }

    showFirstStep() {
        this.steps.first.style.display = 'block';
        this.steps.second.style.display = 'none';
        this.timer.clearTimer();
    }

    showSecondStep() {
        this.timer.clearTimer();
        this.steps.second.style.display = 'block';
        this.steps.first.style.display = 'none';
        document.getElementById('resending-phone').innerText = this.phone
        this.timer.startTimer();

        document.getElementById('auth-step-back').onclick = (e: any) => {
            e.preventDefault();
            this.showFirstStep();
        };
    }
}

export default StepsHandler;
