class Timer {
    startCount: number;
    minEl: HTMLElement;
    secEl: HTMLElement;
    timer: NodeJS.Timeout;

    constructor(seconds: number, minElId: string, secElId: string) {
        this.startCount = seconds;
        this.minEl = document.getElementById(minElId);
        this.secEl = document.getElementById(secElId);
    }

    format(time: string) {
        return time.length > 1 ? time : `0${time}`;
    }

    printTimer(seconds) {
        if (parseInt(seconds) < 1) this.clearTimer();

        this.minEl.innerHTML = this.format(Math.floor(seconds / 60) + '');
        this.secEl.innerHTML = this.format((seconds % 60) + '');
    }

    clearTimer() {
        clearTimeout(this.timer);
    }

    startTimer() {
        this.printTimer(this.startCount);

        this.timer = setInterval(() => {
            const minutes = parseInt(this.minEl.innerHTML);
            let seconds = parseInt(this.secEl.innerHTML) + minutes * 60;
            this.printTimer(--seconds);
        }, 1000);
    }
}

export default Timer;
