import $ from 'jquery';
import imask from 'imask';
import StepsHandler from './stepsHandler';

class AuthHandler {
    authData = {
        phone: undefined,
        expire: undefined
    }
    stepHandler: any;
    authCodeSelector = 'auth-code';
    authPhoneSelector = 'auth-form-phone';
    authCodeError: HTMLElement;
    authCodeInput: HTMLElement;
    authPhoneEl: HTMLFormElement;
    authCodeEl: HTMLInputElement;
    _csrf: string;

    countries = {
        7: '(000)000-00-00',
        374: '(00)00-00-00',
        375: '(00)000-00-00',
        996: '(000)00-00-00'
    };

    constructor() {
        this.authCodeError = document.getElementById('auth-code-error');
        this.authCodeInput = document.getElementById(this.authCodeSelector);
        this.authPhoneEl = document.getElementById(this.authPhoneSelector) as HTMLFormElement;
        this.authCodeEl = document.querySelector('.js-auth-code');

        if (!this.authPhoneEl) return;

        this.initFirstStepForm();

        this.authPhoneEl.onsubmit = (e: any) => {
            e.preventDefault();
            const formData = new FormData(this.authPhoneEl);
            const countryCode = formData.get('countryCode') as string;
            const phone = formData.get('LoginForm[phone]') as string;
            this._csrf = formData.get('_csrf') as string;
            this.fetchVerification(countryCode + phone);
            return;
        };

        this.authCodeEl.oninput = (e) => {
            this.inputCode((e.target as HTMLInputElement).value)
        };

        document.getElementById('js-auth-code-button').onclick = (e) => {
            e.preventDefault();
            this.resendCode();
        }
    }

    async fetchVerification(phone) {
        const _this = this;
        $.ajax({
            method: 'post',
            url: this.authPhoneEl.action,
            data: {
                phone: phone,
                _csrf: this._csrf
            },
            success: (data) => {
                const error = document.getElementById('auth-phone-error')
                error.style.display = 'none'
                _this.authData.phone = data['phone'];
                _this.authData.expire = data['expireTime'];
                console.log(_this.authData.expire)
                if (_this.stepHandler) {
                    _this.stepHandler.timer.clearTimer()
                }
                _this.stepHandler = new StepsHandler(_this.authData);
                _this.stepHandler.showSecondStep();
            },
            error: (data, statusText, errorThrown) => {
                if (errorThrown === 'Forbidden') {
                    const error = document.getElementById('auth-phone-error')
                    error.style.display = 'flex'
                    error.innerText = data?.responseJSON?.error ?? data?.responseText
                }
            }
        });
    }

    fetchLogin(code) {
        const _this = this;
        $.ajax({
            method: 'post',
            url: '/sign-in/login/',
            data: {
                phone: this.authData.phone,
                _csrf: this._csrf,
                code: code
            },
            success: (params, status, data) => {
                _this.authCodeInput.classList.remove('error')
                _this.authCodeError.style.display = undefined
                window.location.href = data.getResponseHeader('x-redirect')
            },
            error: (data, statusText) => {
                if (statusText === 'error') {
                    _this.authCodeError.innerText = data.responseJSON.error
                    _this.authCodeInput.classList.add('error')
                    _this.authCodeError.style.display = 'block'
                }
            }
        })
    }

    resendCode() {
        this.fetchVerification(this.authData.phone)
    }

    inputCode(code) {
        if (/[0-9]{5}/.test(code)) {
            this.fetchLogin(code);
        }
    }

    initFirstStepForm() {
        const codeInput = document.getElementById('auth-phone-code');
        const codeMask = imask(codeInput, {
            mask: '+0[00]',
            overwrite: true,
            lazy: false
        });
    }
}

export default AuthHandler;
