import './Categories.scss';
import { tns } from 'tiny-slider';
import 'tiny-slider/src/tiny-slider.scss';

const Categories = () => {
    tns({
        container: '.categories__list',
        slideBy: 1,
        autoplay: false,
        mode: 'carousel',
        controlsContainer: '.categories__controls',
        controlsText: ['', ''],
        nav: false,
        arrowKeys: true,
        loop: false,
        touch: true,
        mouseDrag: true,
        fixedWidth: 149,
        autoWidth: false,
        gutter: 0,
        responsive: {
            1600: {
                items: 8,
                fixedWidth: 150,
            },
            1400: {
                items: 7,
                fixedWidth: 155,
            },
            1200: {
                items: 6,
                fixedWidth: 148,
            },
            1024: {
                items: 5,
                controls: true,
                fixedWidth: 145,
                // edgePadding: 0
            },
            0: {
                // items: 17,
                fixedWidth: 90,
                // autoWidth: true
                // controls: false,
                // edgePadding: 24
            }
        }
    });
};

export default Categories;
