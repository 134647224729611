import './Products.scss';
import MicroModal from 'micromodal'
import $ from 'jquery';
import AuthHandler from "@components/ModalAuthorization/helpers/authHandler";
import actionFavourites from '@helpers/favourites';

const REVOCATION_CREATED_EVENT = 'revocation-created'
const REVOCATION_CREATED_MODAL = 'revocationCreatedModal'
const REVOCATION_CREATED_MODAL_LINK = 'revocationCreatedModal-link'

declare global {
    interface Window {
        handleCreateRevocation: any;
        handleCreateRevocationClose: any;
    }
}

const Products = () => {
    window.handleCreateRevocation = (productId, event) => {
        const url = new URL('/customer/revocation/create/', document.baseURI)
        $.ajax({
            url: url,
            type: 'POST',
            data: {
                productId: productId,
                _csrf: event.target.dataset.csrftoken
            },
            success: function (data) {
                const url = new URL('/customer/revocation/index/?id=' + data.revocationId, document.baseURI)
                const event = new CustomEvent(REVOCATION_CREATED_EVENT, {
                    detail: {
                        url
                    }
                })
                document.dispatchEvent(event)
            },
            error: function () {
                new AuthHandler()
                MicroModal.show('authorizeModal')
            }
        })
    }

    window.handleCreateRevocationClose = (event) => {
        event.preventDefault();
        MicroModal.close(REVOCATION_CREATED_MODAL);
        const revocationPage = window.open(event.target.href,'_blank');
        revocationPage.blur();
        window.focus();
    }

    document.addEventListener(REVOCATION_CREATED_EVENT, (event: CustomEvent) => {
        const revocationCreatedLink = document.getElementById(REVOCATION_CREATED_MODAL_LINK) as HTMLAnchorElement;
        revocationCreatedLink.href = event.detail.url
        MicroModal.show(REVOCATION_CREATED_MODAL);
    });
    
    window.addEventListener("load", () => {
        document.querySelectorAll('.js-favorite-delete').forEach((el) => {
            const productId = (el as HTMLElement).dataset.id;
            productId && el.addEventListener('click', (e) => {
                e.preventDefault();
                e.stopPropagation();
                actionFavourites(productId, 'delete').then((data: any) => {
                    if (data?.success) {
                        window.location.reload();
                    }
                })
            })
        })
    });
};

export default Products;
