import DropdownHeader from './components/DropdownHeader';
import DropdownPersonal from './components/DropdownPersonal';
import DropdownSearch from './components/DropdownSearch';
import DropdownMenu from './components/DropdownMenu';
import './Header.scss';

const Header = () => {
    DropdownHeader();
    DropdownPersonal();
    DropdownMenu();
    DropdownSearch();
};

export default Header;
